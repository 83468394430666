<template>
    <div class="iframe-test">
        <div>code 測試</div>
        <div class="input">
            <q-input
                class="input"
                outlined
                clearable
                v-model="iframe"
                placeholder="嵌入Code"
                @keypress.enter="preview"
            />
        </div>
        <br>
        <div>
            預覽:
        </div>
        <div v-if="iframe" class="voice-frame" v-html="iframe">
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    components: {},
    data() {
        return {
            iframe: ref(''),
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        preview() {
            console.log('preview');
        },
    },
};
</script>

<style lang="scss" scoped>
.iframe-test{
    padding: 30px;
    width: 80vw;
}
</style>
